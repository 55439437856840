/* Colors */

:root {
    --base: #FFFFFF;
    --primary: #FF6600;
    --secondary: #EDEEF0;
    --dark-grey: #00000080;
    --font-black: #1E1F24;
    --dark-grey-alternate: #747474;
    --medium-grey-alternate: #B5B9BF;
    --black: #000000;
    --medium-grey-alternate-1: #00000033;
    --medium-grey-alternate-2: #DCDDE0;
    --component-button-highlight-border: #0D2F40;
    --component-button-highlight-background: #0F4D62;
    --dark-orange: #E04B00;

    --dark-mode-body: #101114;
    --dark-mode-tile: #1e1f24;

    --text-size-300: 16px;

    --transition-time: 0.7s;
    --color-hover: rgb(181, 185, 191);
    --color-text-dark-theme: rgb(131, 136, 143);
  }
  
  body * {
    box-sizing: border-box;
  }
  

/* Fonts */
  @font-face {
    font-family: 'Gibson';
    src: url('../public/fonts/Gibson.woff') format('woff'),
         url('../public/fonts/Gibson.woff2') format('woff2'),
         url('../public/fonts/Gibson.eot') format('eot');
  }

  @font-face {
    font-family: 'Gibson-Medium';
    src: url('../public/fonts/Gibson-Medium.woff') format('woff'),
         url('../public/fonts/Gibson-Medium.woff2') format('woff2'),
         url('../public/fonts/Gibson-Medium.eot') format('eot');
  }

  @font-face {
    font-family: 'Gibson-SemiBold';
    src: url('../public/fonts/Gibson-SemiBold.woff') format('woff'),
         url('../public/fonts/Gibson-SemiBold.woff2') format('woff2'),
         url('../public/fonts/Gibson-SemiBold.eot') format('eot');
  }

  h1 {
    font-family: 'Gibson-SemiBold';
    font-size: 40px;
    margin: 0;
  }
  
  h2 {
    font-family: 'Gibson-SemiBold';
    font-size: 30px !important;
    margin: 0;
  }

  h3 {
    font-family: 'Gibson-SemiBold';
    font-size: 24px !important;
    margin: 0;
  }


  p, span, li {
    font-family: 'Gibson';
    color: var(--font-black);
  }


/* Queries */ 

@media (max-width: 575px) {
  h1 {
    font-size: 22px !important;
  }
  h2 {
    font-size: 20px !important;
  }
  h3 {
    font-size: 18px !important;
  }
  p {
    font-size: 18px;
  }
}