.carousel-indicators [data-bs-target] {
    width: 9px !important;
    height: 9px !important;
    border-radius: 50%;
    margin-bottom: 0;
    background-color: #000;
    opacity: 0.5;
}

.carousel-indicators {
    bottom: -50px;
}

.carousel-indicators .active {
    opacity: 1;
}

.darkModeCarousel .carousel-indicators .active {
    background-color: #fff !important;
}

.activeWhite {
    background-color: #fff !important;
}