.anmelden {
    border-radius: 5px;
    border: 1px solid var(--component-button-highlight-border);
    background: var(--component-button-highlight-background);
    display: flex;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;    
    gap: 9px;
    color: var(--base);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    &:hover {
        border: 1px solid #153E51;
        background: #125E77;
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.33);
    }
    &:focus {
        border: 1px solid #102E3C;
        background: #1A617A;
        /* UI Effects/Focus Glow */
        box-shadow: 0px 0px 1px 4px #A5DBE5, 0px 0px 1px 4px #A5DBE5;
    }
}  

.mobileMenu .anmelden {
    width: 100%;
}

.actionButton {
    border-radius: 5px;
    border: 1px solid var(--dark-orange);
    background: var(--primary);
    color: var(--base);
    font-family: 'Gibson-SemiBold';
    font-size: 15px;
    width: 358px;
    height: 46px;
    cursor: pointer;
    & > div {
        position: relative;
        top: 3px;
    }
    &:hover {
        border: 1px solid #F50;
        background: #FF751A;
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.33);

    }
    &:focus {  
        border: 1px solid #1A617A;
        background: #FF751A;
        /* UI Effects/Focus Glow */
        box-shadow: 0px 0px 1px 4px #A5DBE5, 0px 0px 1px 4px #A5DBE5;
    }
}

@media (max-width: 575px) {
    .actionButton {
        width: 100%;
    }
}

/* Dark mode */

.darkMode {
    .anmelden {
        background-color: #088291;
        border: 1px solid #066578;
        &:hover {
            background-color: #158fa7;
            border-color: #137285;
            box-shadow: 0px 1px 4px #ffffff54;
        }
    }
    .actionButton {
        background-color: #E04B00;
        border: 1px solid #A83200;
        &:hover {
            background-color: #ed580d;
            border-color: #b53f0d;
            box-shadow: 0px 1px 4px #ffffff54;
        }
    }
}