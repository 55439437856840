/* General */

.app {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    background-color: var(--secondary) !important;
}

/* Header */

.headerContainer {
    height: 72px;
    background-color: var(--base);
    width: 100%;
    display: flex;
    align-items: center;
    & a {
        text-decoration: none;
    }
}

.headerContainer__inner {
    width: 100%;
    max-width: 1014px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
}

.focusPoint {
    display: flex;
    flex-wrap: nowrap;
    gap: 6px;
    align-items: center;
}

.logo {
    width: 110px;
}

.claim {
    font-size: 12px;
    line-height: 14px;
    width: 111px;
    margin: 0;
}

.navigation, .account {
    gap: 20px;
}

.navigation span {
    font-size: var(--text-size-300);
    color: var(--font-black);
    line-height: 24px;
    font-family: 'Gibson-Medium';
}

.account img {
    width: 20px;
    height: 20px;
}

.navMenu {
    display: none;
}

.mobileMenu {
    width: 0;
    height: 100dvh;
    opacity: 0;
    visibility: hidden;
    position: fixed;
    top: 0;
    right: 0;
    background-color: var(--base);
    z-index: 10;
    transition: 0.3s;
    & .closeButton {
        -webkit-appearance: none;
        appearance: none;
        border: none;
        background: none;
        height: 72px;
        width: 100%;
        padding: 0 18px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &  a button {
        width: 224px;
        position: absolute;
        bottom: 18px;
    }
    & * {
        font-family: 'Gibson-Medium';
        font-size: 16px;
        display: none !important;
    }
    & a {
        padding: 0 18px;
    }
    & p {
        margin: 0;
    }
    & img {
        width: 16px;
        height: 16px;
    }
}

.showMenu {
    opacity: 1;   
    visibility: visible;
    width: 260px;
    height: 100vh;
    & * {
        display: flex !important;
    }
}

.mobileMenuItem {
    width: 224px;
    height: 62px;
    border-bottom: 1px solid #000;
    display: flex;
    align-items: center;

}

/* Impressions */

.impressionsContainer {
    width: 100%;
}

.impressionsContainer__inner {
    max-width: 1014px;
    margin: 0 auto;
    position: relative;
}

.impressionsFullWidth {
    width: 100%;
    height: 360px;
    max-width: 1014px;
    overflow: hidden;
    border-radius: 4px;
    & img {
        width: 100%;
        height: 100%;
        border-radius: 4px;
        object-fit: cover;
        transition: 0.7s ease-in-out;
        cursor: pointer;
    }
}

.impressionsFullWidth:hover  img {
    transform: scale(1.1);
    border-radius: 4px;
}

.positionBottom {
    object-position: bottom;
}

.positionLightTop {
    object-position: 50% 42%;
}

.positionSportLarge {
    object-position: 50% 83%;
}

.adHint {
    font-size: 12px;
    color: var(--dark-grey-alternate);
    display: block;
    width: 82px;
    height: 12px;
    position: absolute;
    right: 0;
    top: -16px;
    text-align: end;
}

.impressionsContainer__bottomRow {
    width: 100%;
    max-width: 1014px;
    min-width: 100%;
    display: flex;
    flex-wrap: no-wrap;
    gap: 3px;
    height: 228px;
    margin-top: 3px;
    overflow: hidden;
    & img {
        cursor: pointer;
    }
}

.impressions15 {
    flex: 1 1 15%;
    height: 228px;
    transition: var(--transition-time);
    & img {
        width: 100%;
        height: 100%;
        border-radius: 4px;
        object-fit: cover;
    }
}

.impressions21 {
    flex: 1 1 21%;
    border-radius: 4px;
    height: 228px;
    transition: var(--transition-time);
    & img {
        width: 100%;
        height: 100%;
        border-radius: 4px;
        object-fit: cover;
    }
}

.impressions28 {
    flex: 1 1 28%;
    border-radius: 4px;
    height: 228px;
    transition: var(--transition-time);
    & img {
        width: 100%;
        height: 100%;
        border-radius: 4px;
        object-fit: cover;
    }
}

.impressionsRest {
    flex: 1 1 35%;
    border-radius: 4px;
    height: 228px;
    transition: var(--transition-time);
    & img {
        width: 100%;
        height: 100%;
        border-radius: 4px;
        object-fit: cover;
    }
}

.impressionsContainer__bottomRow:hover .flexImage {
    flex: 1 1 25%; 
  }
  
  .impressionsContainer__bottomRow:hover .flexImage:hover {
    flex: 1 1 45%;
  }


.impressionsCarousel {
    display: none;
}

.carousel {
    height: 250px;
    max-height: 400px;
}

.carouselImage {
    width: 100vw !important;
    height: 250px;
    object-fit: cover;
}

.nextIcon {
    background-image: url('../../public/images/nextIcon.svg') !important;
    background-repeat: no-repeat;
    background-size: contain;
    background-size: cover;
    background: #F7F7F7;
    width: 24px;
    height: 24px;
}

.prevIcon {
    background-image: url('../../public/images/prevIcon.svg') !important;
    background-repeat: no-repeat;
    background-size: contain;
    background-size: cover;
    width: 24px;
    height: 24px;
}

.nextIconContainer {
    border-radius: 24px;
    background: #F7F7F7;
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0px;
    flex-shrink: 0;
    padding: 12px;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.50);
    opacity: 0.66;
}

.prevIconContainer {
    border-radius: 24px;
    background: #F7F7F7;
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0px;
    flex-shrink: 0;
    padding: 12px;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.50);
    opacity: 0.66;
}

.nextIconContainer, .prevIconContainer {
    &:hover{
        opacity: 0.9;
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.50);
    } 
    &.nextIcon, .prevIcon {
        box-shadow: none;
    }       
    &:focus {
        opacity: 0.9;
        border: 1px solid #1A617A;
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.50);
    }
}

.carousel-indicators {
    bottom: -20px;
}

.impressionsCarousel .carousel-indicators [data-bs-target] {
    width: 9px !important;
    height: 9px !important;
    border-radius: 50%;
}

.carouselNavButtons {
    width: 9px !important;
    height: 9px !important;
    border-radius: 50%;
}


/* Vehicle */

.vehicleContainer, .dealerContainer {
    width: 100%;
}

.vehicleContainer__inner, .dealerContainer__inner {
    max-width: 1014px;
    margin: 0 auto;
    position: relative;
    background-color: var(--base);
    padding: 35px;
    box-shadow: 0px 1px 2px #00000080;
    border-radius: 4px;
    & ol {
        padding-left: 24px;
    }
    & li {
        font-size: 24px;
    margin-top: 16px;
    margin-bottom: 16px;
    line-height: 30px;
    padding-left: 18px;
    }
    & li::marker {
        color: var(--primary);
        width: 26px !important;
        font-family: 'Gibson-SemiBold' !important;
    }
}

.introText {
    font-size: 24px;
    margin-top: 20px;
    margin-bottom: 36px;
    line-height: 30px;
}

.infoDetailsContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 35px;
    .priceIndication {
        display: flex;
        flex-direction: column;
        gap: 3px;
        & p:first-child {
            font-family: 'Gibson-Medium';
            font-size: 16px;
            line-height: 16px;
            margin: 0;
        }
        & p:nth-child(2) {
            font-family: 'Gibson-SemiBold';
            font-size: 24px;
            line-height: 24px;
            margin: 0;
        }
    }

}

.specs {
    border-radius: 5px;
    box-shadow: 0px 1px 2px #00000080;
    background-color: var(--secondary);
    padding: 35px;
    gap: 35px;
    display: flex;
    flex-direction: column;
    margin-bottom: 35px;
    & .specsRow {
        display: flex;
        align-items: center;
        & .specsCaption {
            white-space: nowrap;
            display: flex;
            align-items: center;
        }
        & .specsCaption img {
            width: 25px;
        }
        & .specsCaption span {
            font-family: 'Gibson-SemiBold';
            font-size: 24px;
            margin-left: 9px;
            width: 255px;
            display: inline-block;
        }
        & p {
            margin: 0 0 0 40px;
        }
    }
}

.additionalInfo {
    & * {
        font-size: 14px;
        white-space: break-spaces;
    }
    & a {
        color: var(--primary);
    }
}

/* Dealer Infos */ 

.dealerContainer__inner {
    padding: 47px 35px;
}

.dealerInfo {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: space-between;
}

.centeredDealerInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}

.specificDealer {
    display: flex;
    flex-direction: column;
    flex: 0 0 50%;
    & .specificDealerContact {
        margin: 24px 0;
        line-height: 19px;
    }
    & p {
        margin: 0;
    }
}

.otherDealer {
    display: flex;
    flex-direction: column;
    flex: 0 0 50%;
    justify-content: space-between;
    align-items: flex-end;
    & h3 {
        width: 270px;
        position: relative;
        left: -87px;
        margin-bottom: 24px;
    }

}

.inputDealer {
    margin: 7px 0 17px;
    border: 1px solid #B5B9BF;
    border-radius: 5px;
    height: 44px;
    padding: 12px 14px;
    &:hover {
       border: 1px solid #C3C6CB
    }
    &:focus {
        border: 1px solid #1A617A;
        /* UI Effects/Focus Glow */
        box-shadow: 0px 0px 1px 4px #A5DBE5, 0px 0px 1px 4px #A5DBE5;
    }
}

.dealerLine {
    position: relative;
    &::before {
        content: '';
        position: absolute;
        height: 190px;
        bottom: 0;
        left: 50%; 
        width: 1px; 
        background-color: var(--medium-grey-alternate-2);
        transform: translateX(-50%); 
        z-index: 1;
    }
}

.bottomInfo {
    max-width: 1014px;
    font-size: 11px;
    margin: 20px auto;
}

/*v1.2 additions */

.buttonSpacing {
    margin: 6px 0 !important;
}

.biggerH2 {
    font-size: 32px !important;
}

sup {
    position: relative;
    top: -5px !important;
    font-size: 9px;
}

.supSmall {
    position: relative;
    top: -4px !important;
    font-size: 7px !important;
}


/* Queries */
@media (max-width: 1007px) {
    .navigation, .account {
        display: none;
    }
    .headerContainer {
        padding: 18px;
    }
    .navMenu {
        display: block;
        cursor: pointer;
    }
    .bottomInfo {
        padding: 0 15px;
    }
    .adHint {
        right: 14px;
    }
}

@media (max-width: 910px) {
    .dealerLine {
        flex: 0 0 100%;
        margin: 32px 0;
        &::before {
            height: 1px;
            width: 100%;
        }
    }
    .specificDealer {
        flex: 0 0 100%;
    }
    .otherDealer {
        flex: 0 0 100%;
        align-items: flex-start;
    }
    .otherDealer p {
        margin: 0 0 6px;
    }
    .otherDealer h3 {
        position: static;
    }
    .dealerContainer__inner {
        padding: 30px;
    }
}

@media (max-width: 756px) {
    .infoDetailsContainer {
        flex-wrap: wrap;
    }
    .specificDealer, .otherDealer {
        width: 300px;
    }
    .impressionsGallery {
        display: none;
    }
    .impressionsCarousel {
        display: block;
    }
    .vehicleContainer {
        margin-top: 15px;
    }
    .specs {
        gap: 25px;
    }
}

@media (max-width: 640px) {
    .infoDetailsContainer button {
        margin-top: 30px;
    }
    .specsRow {
        flex-wrap: wrap;
        gap: 9px;
    }
    .specsCaption {
        flex: 0 0 100%;
    }
    .specsRow p {
        margin: 0 0 0 34px !important;
    }
}

@media (max-width: 575px) {
    .infoDetailsContainer button {
        margin-top: 20px;
    }
    .infoDetailsContainer a {
        width: 100%;
    }
    .introText {
        font-size: 18px;
        margin-bottom: 20px;
    }
    .priceIndication {
        & p:first-child {
            font-size: 15px;
        }
        & p:nth-child(2) {
            font-size: 22px;
        }
    }
    .specs {
        padding: 15px;
    }
    .specsCaption {
        display: flex;
        align-items: center;
        & span {
            font-size: 20px !important;
        }
    }
    .vehicleContainer__inner, .dealerContainer__inner {
        padding: 30px;
    }
    .specificDealer, .otherDealer, .otherDealer a, .otherDealer h2 {
        width: 100%;
    }
    .dealerContainer__inner li {
        font-size: 18px;
      }
    .biggerH2 {
        font-size: 20px !important;
      }
}

/* Dark mode declaration */

.darkMode {
    .headerContainer, .mobileMenu, .vehicleContainer__inner, .dealerContainer__inner {
        background-color: var(--dark-mode-tile);
        box-shadow: 0 1px 1.5px #00000080,inset 0 -2px 0 #191A1F;
    }
    p, span, h1, h2, h3, button, li {
        color: var(--base);
    }
    footer a:hover {
        color: var(--color-hover);
    }
    .app {
        background-color: var(--dark-mode-body) !important;
    }
    .mobileMenuItem {
        border-bottom: 1px solid #23373D;
    }
    .specs {
        background-color: var(--dark-mode-body);
    }
    .additionalInfo p {
        color: var(--base);
    }
    .inputDealer {
        background: #1E1F24;
        border-color: #5A5E66;
        color: var(--base);
        &:hover {
            background: #1E1F24;
            border-color: #676b73;
            box-shadow: 0 1px 4px hsla(0,0%,100%,0.33);
        }
        &:focus {
            border-color: #A5DBE6;
            box-shadow:  0 1px 4px #1A617A;
        }
    }
    .bottomInfo {
        color: var(--color-text-dark-theme);
    }
    .modal-dialog .modal-content {
        background-color: #101114;
        border: none;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      }
}