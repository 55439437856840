body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.lower2 {
  position: relative;
  top: 3px;
  font-size: 11px !important;
}  

.modal-dialog {
  width: 100vw !important;
  max-width: 844px !important;
padding: 18px;
}

.modal-title {
  font-size: 20px !important;
  font-family: "Gibson", sans-serif !important;
  font-weight: 600 !important;
}

.modal-header {
  border: none !important;
  padding: 16px 16px 0 !important;
}

.darkModal {
  background-color: #101114;
  border: none !important;
  padding: 16px 16px 0 !important;
}

.darkModal .btn-close {
  color: #fff !important;
}

.close {
  appearance: none;
  width: 30px;
  background: none;
  border: none;
}

.darkModalColor {
  color: #fff !important;
}

.modal-body img {
  width: 100%;
  height: auto;
  padding-bottom: 16px;
}

footer {
  margin: 32px auto 0;
  width: 100%;
  background-color: var(--dark-mode-body);
  padding-bottom: 27px;
  & .footerContainer {
    max-width: 1014px;
    position: relative;
    margin: 0 auto;
  }
  & ul {
    display: flex;
    width: 100%;
    list-style: none;
    gap: 18px;
    position: relative;
    left: -275px;
    padding: 27px 0 0;
    flex-wrap: wrap;
    margin-bottom: 0;
  }
  & li {
    font-family: "Gibson", sans-serif;
    color: var(--color-text-dark-theme);
  }
  & a {
    text-decoration: none;
  }
  & li:hover {
    color: var(--color-hover);
}
}

@media (max-width:1600px) {
  footer {
    & ul {
      position: relative;
      left: 0;
      padding: 27px 15px;
    }
  } 
}